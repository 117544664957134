<template>
  <div class="role-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="roleFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="roleForm"
          :rules="roleFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">角色信息</el-divider>
          <!-- 输入框-阿米巴 -->
          <!-- <ml-select
          v-if="isBusinessDepartment"
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="roleForm.companyId"
          :disabled="roleForm.roleCode === 'SUPERADMIN'"
          @change="selectUnit"
        /> -->
          <!-- 输入框-角色名称 -->
          <ml-input
            prop="roleName"
            label="角色名称:"
            placeholder="请输入角色名称"
            v-model.trim="roleForm.roleName"
          />
          <!-- 输入框-角色描述 -->
          <ml-input
            prop="remark"
            label="角色描述:"
            placeholder="请输入角色描述"
            type="textarea"
            :rows="5"
            v-model.trim="roleForm.remark"
            show-word-limit
            maxlength="100"
          />
          <!-- 树 -角色权限-->
          <ml-tree
            ref="roleTreeRef"
            prop="moduleIds"
            label="角色权限:"
            :data="roleMenuData"
            placeholder="请选择角色权限"
            v-model="roleForm.moduleIds"
            v-model:halfCheckedKeys="halfCheckedKeys"
            treeClass="ml-tree role-tree"
            height="600px"
            node-key="id"
            :props="{ label: 'title' }"
          />

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitUser"
            @click-cancel="clickCancelUser"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { tabberHeight, searchParams, getTabberData, setDefaultChenckKeysFs } from '@/utils'
export default {
  name: 'RoleManageAction',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const route = useRoute()
    const router = useRouter()
    // 获取路径参数
    const { roleId } = route.query
    // 设置导航标题文本
    const content = ref(roleId ? '修改角色' : '添加角色')
    /* 开始 用户表单 **********************************/
    // 表单配置
    const roleTreeRef = ref()
    const roleFormRef = ref()
    const roleForm = reactive({
      roleName: '',
      remark: '',
      companyId: '',
      moduleIds: [],
      roleCode: ''
    })
    // 伴选角色
    const halfCheckedKeys = ref([])

    // 阿米巴
    const unitOptions = ref([])
    // 菜单
    const roleMenuData = ref([])

    // 角色权限菜单
    const getRoleMenuData = async () => {
      const roleMenuDataParams = { parentId: 0 }
      // 处理事业部，需加入单位ID
      // if (isBusinessDepartment.value) {
      //   roleMenuDataParams.companyId = companyId
      // }
      const roleData = await getTabberData(
        dispatch,
        'fetchGetDistirbuteModuleTrees',
        roleMenuDataParams
      )
      roleMenuData.value = roleData.data || []
    }
    // const selectUnit = companyId => {
    //   roleMenuData.value = getTabberData(companyId)
    //   roleForm.moduleIds = []
    // }
    // 监听阿米巴
    // watch(
    //   () => roleForm.companyId,
    //   newvalue => {
    //     getRoleMenuData(newvalue)
    //   }
    // )

    // 校验
    const roleFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      //moduleIds: [{ required: true, message: '至少选择一个角色权限', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitUser = () => {
      roleFormRef.value.CustomFormRef.validate()
        .then(() => {
          const roleParams = searchParams(roleForm, ['roleCode'])
          if (!isBusinessDepartment.value) {
            delete roleParams.companyId
          }
          roleTreeRef.value
          if (halfCheckedKeys.value.length) {
            roleParams.moduleIds = [...roleParams.moduleIds, ...halfCheckedKeys.value]
          }
          if (content.value === '添加角色') {
            dispatch('fetchAddSysRole', roleParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/roleManage' })
              }
            })
          } else {
            roleParams.roleId = roleId
            dispatch('fetchUpdateSysRole', roleParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/roleManage' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUser = () => {
      router.push({ path: '/roleManage' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      // const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      // unitOptions.value = data || []
      // if (data.length > 0) {
      //   roleForm.companyId = data[0].companyId
      // }
      getRoleMenuData()

      if (content.value === '修改角色') {
        // 获取单个详情
        await dispatch('fetchGetSAysRole', roleId).then(data => {
          if (data && data.code === 200) {
            Object.keys(roleForm).map(item => {
              if (!['moduleIds'].includes(item)) {
                roleForm[item] = data.data[item]
              }
            })
          }
        })
        // 获取角色选中列表
        await dispatch('fetchGetSysModulesByRole', { roleId }).then(data => {
          if (data && data.code === 200) {
            const nTreeObj = setDefaultChenckKeysFs(data.data)
            roleForm.moduleIds = nTreeObj.moduleIds
            halfCheckedKeys.value = nTreeObj.halfCheckedKeys
          }
        })
      }
    })
    return {
      roleFormRef,
      isBusinessDepartment,
      content,
      roleForm,
      roleMenuData,
      roleFormRules,
      unitOptions,
      clickSubmitUser,
      clickCancelUser,
      // selectUnit,
      halfCheckedKeys,
      roleTreeRef,
      tabberHeight
    }
  }
}
</script>

<style lang="scss">
.role-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
